import React, {useEffect, useRef, useState} from 'react'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {Box} from '@material-ui/core'
import StarRateIcon from '@material-ui/icons/StarRate'
import Button from '@material-ui/core/Button'
import LOGO from './images/logo_horizontal.png'
import TextField from '@material-ui/core/TextField'
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt'
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt'
import CircularProgress from '@material-ui/core/CircularProgress'

import {getTasks, sendComment, sendRating, sendTasks} from './API'

import './App.css'
import {AxiosResponse} from "axios"
import {TaskI} from "./interfaces"

const PRIMARY_COLOR = '#00AEF4'
const SECONDARY_COLOR = '#0090CA'
const ACTION_COLOR = '#FFD700'
const ERROR_COLOR = '#F92316'
const TEXT_COLOR = '#000000de'
const WHITE = '#fff'

const CssTextField = withStyles({
    root: {
        display: 'block',
        '& label.Mui-focused': {
            color: TEXT_COLOR,
        },
        '& .MuiFilledInput-underline': {
            margin: '8px 0',
        },
        '& .MuiFilledInput-underline:after': {
            borderBottomColor: ACTION_COLOR,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: SECONDARY_COLOR,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: SECONDARY_COLOR,
            },
            '&:hover fieldset': {
                borderColor: SECONDARY_COLOR,
            },
            '&.Mui-focused fieldset': {
                borderColor: SECONDARY_COLOR,
            },
        },
    },
})(TextField);

const ColorButton = withStyles((theme) => ({
    root: {
        color: TEXT_COLOR,
        backgroundColor: ACTION_COLOR,
        marginTop: 8,
        borderRadius: 8,
        '&:hover': {
            backgroundColor: ACTION_COLOR,
            opacity: 0.8
        },
    },
}))(Button);

const useStyles = makeStyles(theme => ({
    header: {
        padding: '8px 0',
        display: 'block'
    },
    title: {
        paddingBottom: 8,
        fontWeight: 'bold',
        display: 'block'

    },
    paperColor: {
        borderRadius: 16,
        backgroundColor: PRIMARY_COLOR,
        padding: 32
    },
    taskPaper: {
        display: 'flex',
        alignItems: 'center',
        width: 'calc(100% - 16px)',
        borderRadius: 8,
        backgroundColor: SECONDARY_COLOR,
        padding: 8,
        margin: '8px 0'
    },
    stars: {
        marginLeft: -8
    },
    root: {
        // padding: 16,
        margin: '0 auto'
    },
    centered: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '8px 0'
    },
    loader: {
        color: ACTION_COLOR
    },
    wrapper: {
        maxHeight: 300,
        // overflowX: 'hiden',
        overflowY: 'scroll'
    },
    logo: {
        width: '50%',
        // height: 150,
        backgroundSize: 'contain',
        background: `url(${LOGO}) no-repeat center`,
        backgroundPosition: 'left',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        // margin: 32
    },
    logoWrapper: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: 150,
        margin: '16px auto'
    },
    reaction: {
        cursor: 'pointer',
        transition: 'color 0.2s ease'
    }
}))

//pm2 start my-app/node_modules/react-scripts/scripts/start.js --name "email app"
//http://localhost:8001/?job={%22id%22:19,%22address%22:%22Testovacia%20Adresa%2011%22}&stars=4&finish=466&user=3

const queryString = decodeURIComponent(window.location.search)
const urlParams = new URLSearchParams(queryString)
const rating: number = parseInt(urlParams.get('rating') as string || '');
const comment = urlParams.get('comment') || '';
const address = urlParams.get('address') || '';
const finishId = parseInt(urlParams.get('finishID') as string || '');

console.log({
    queryString,
    urlParams,
    rating,
    comment,
    address,
    finishId
})


type taskResponse = AxiosResponse | TaskI[]
// const x: mm = false
const placeholder: taskResponse = []

export default function App() {

    const [isAfterFirstTaskRating, afterFirstTaskRating] = useState(false)
    const [isFinished, setFinish] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [tasks, setTasks] = useState(placeholder)
    const classes: any = useStyles()

    const getTasksHandler = ()=> {
        setLoading(true)
        getTasks(finishId).then(({data: {tasks}}: any)=>{
            setTasks(tasks)
            setLoading(false)
        })
    }

    useEffect(() => {
        getTasksHandler()
    }, [])


    const refTextArea = useRef<any>()

    const renderStars = () => {
        return rating && [...Array(rating)].map(i => (
            <StarRateIcon key={i} style={{color: ACTION_COLOR, fontSize: 50}}/>
        ));
    };

    const setReaction = async (id: number, value: string)=>{
        let slicedTasks: any = (tasks as any[]).slice()
        const index = (tasks as any[]).findIndex((task: TaskI)=> task.id === id);
        slicedTasks[index].reaction = value
        try {
            if(!isAfterFirstTaskRating) {
                await sendRating({
                    rating: rating,
                    comment: comment.trim(),
                    finishID: finishId
                })
            }

            await sendTasks({
                tasks: slicedTasks.filter((task: TaskI)=> task.reaction),
                finishID: finishId
            })
                .then(
                (response: any)=>{
                    if (response.status === 200) {
                        afterFirstTaskRating(true)
                    }
                }
            )
        } catch (error) {
            return error
        }
        return setTasks(slicedTasks)
    }

    const renderTask =(task: TaskI)=>{
        const isLikeColor = task?.reaction === 'LIKE' && ACTION_COLOR || WHITE;
        const isDislike = task?.reaction === 'DISLIKE' && ERROR_COLOR || WHITE;
        return <Paper elevation={3} className={classes.taskPaper}>
            {task.name} <ThumbUpAltIcon className={classes.reaction} onClick={()=>setReaction(task.id,'LIKE')} style={{color: isLikeColor, marginLeft: 'auto'}}/>
            <ThumbDownAltIcon className={classes.reaction} onClick={()=>setReaction(task.id,'DISLIKE')} style={{color: isDislike,margin: '0 16px'}}/>
        </Paper>
    }

    const renderAllTasks = ()=> {
        console.log(tasks)
        return tasks && (tasks as TaskI[])?.map((task: TaskI)=> renderTask(task))
    }

    const handleTextArea = async () => {
        const value = refTextArea?.current?.value
        try {
        	await sendComment({
        		comment: value.trim(),
        		finishID: finishId
        	})
            setFinish(true)

        } catch (error) {
        	return error
        }
    }

    return (<>
            <div className={classes.logoWrapper}>
                <div className={classes.logo} />

            </div>
            {!isFinished ?      <Grid className={classes.root} item lg={6} md={6} xs={12}>
                <Paper elevation={3} className={classes.paperColor}>
                    <Typography variant="h6" gutterBottom className={classes.header}>
                        Vaše hodnotenie zákazky
                    </Typography>
                    <Typography variant="h6" gutterBottom className={classes.title}>
                        {address}
                    </Typography>
                    <Box className={classes.stars}>{renderStars()}</Box>
                    <CssTextField className={classes.input} inputRef={refTextArea} fullWidth  variant="filled"
                                  label="Dajte nám vedieť" />
                    <div className={`${classes.wrapper}`}>

                        {!isLoading ? renderAllTasks() : <div className={classes.centered}>
                            <CircularProgress
                                className={classes.loader}
                                size={40}
                                thickness={4}
                                value={100}
                            />
                        </div>
                        }
                        <div className={classes.centered} style={{ marginTop: 16}}>
                        </div>
                    </div>
                    <ColorButton fullWidth onClick={handleTextArea} variant="contained">
                        Odoslať
                    </ColorButton>
                </Paper>
            </Grid> : <Grid className={classes.root} item lg={6} md={6} xs={12}>
                <Paper elevation={3} className={classes.paperColor}>
                    <Typography variant="h6" >
                        Ďakujeme za vaše hodnotenie
                    </Typography>
                </Paper>
            </Grid>
            }
        </>
    )
}

App.defaultProps = {}

App.propTypes = {}
