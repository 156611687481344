import 'dotenv/config';
import axios from 'axios';
import { TaskI, TaskGetI, CommentPostI, RatingDataI } from './interfaces';
// todo  prorobit na env alebo nieco podobne
// const url = 'http://localhost:88/ratings';
// const url = process.env.POSTGRESQL_USER || 'https://backend-wpnifcgoma-ew.a.run.app/api/ratings';
const url = process.env.REACT_APP_RATING_HOST || 'http://localhost:80';

const commentUrl = url + '/api/v1/ratings/comment';
const ratingUrl = url + '/api/v1/ratings';
const postTasksUrl = url + '/api/v1/ratings/tasks';
const getTasksUrl = (id: number) => url + `/api/v1/ratings/${id}/tasks`;

export const sendRating = (data: RatingDataI) => {
  return axios.post(ratingUrl, data);
};

export const getTasks = (id: number) => {
  return axios.get(getTasksUrl(id));
};

export const sendTasks = (data: TaskGetI) => {
  return axios.patch(postTasksUrl, data);
};

export const sendComment = (data: CommentPostI) => {
  return axios.patch(commentUrl, data);
};
